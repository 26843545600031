import { Autocomplete, Divider, Popper, Typography } from "@mui/material";
import React, { useState, useMemo } from "react";
import { TextField } from '@mui/material'
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Box } from "@mui/system";

const ComboBox = ({ options, label, sx, setSelectedId, selectedId, required, disabled, onKeyUp }) => {
  const [text, setText] = useState("");
  const value = useMemo(() => options.find(item => item.id === selectedId) || null, [selectedId, options])
  return <Autocomplete
    clearIcon={<BackspaceIcon fontSize="small" color="secondary"/>}
    disabled={disabled}
    sx={sx}
    size="small"
    options={options}
    groupBy={options && options[0] && options[0].group? (option)=>option.group: null}
    renderInput={(params) => <TextField {...params} 
      label={label}
      required={required || false}
      error={required && selectedId == null}
    />}
    renderGroup={(params) =>{
      console.log("render group params::", params)
      return (
          <Box key={params.key} >
            <Typography fontWeight="bold" sx={{pl:1, color:"primary.main"}} >{params.group}</Typography>
            {params.children}
            <Divider sx={{my: 1}} variant="middle" />
          </Box>
      )
    }}
    renderOption={(props, option) => {
      return (
        <li {...props} key={option.id} style={{paddingLef:16,paddingTop:0}}>
          {option.label}
        </li>
      );
    }}
    onKeyUp={onKeyUp}
    value={value}
    onChange={(e, newValue) => { setSelectedId(newValue ? newValue.id : null) }}
    inputValue={text}
    onInputChange={(e, newInputValue) => setText(newInputValue)}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    PopperComponent={(props) => (
      <Popper {...props} style={{ width: 'auto', minWidth: 120 }} />
    )}
  />
}

export default  React.memo(ComboBox)